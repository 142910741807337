.Form{
    width: 450px;
    max-width: 100%;
}

.cs-login{
    border: 1px solid var(--brand-color) !important;
    padding: 24px 0px;
}

.w-100{
    width: 100% !important;
}