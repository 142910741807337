
.animation{ transition-property:all;transition-timing-function:ease;transition-duration:0.5s;}
.transparent-bg{
    background-color: transparent;
}


.ml-navbar{display: flex;justify-content:center; 
    transition-property:all;
    transition-timing-function:linear;transition-duration:0.5s;width:100%;position: fixed;z-index: 100;top:0;left: 0px;
    border-bottom: 1px solid var(--tertiary-color);
    }
.ml-navbar-move{
    top:-400px
}
.ml-navbar>.ml-navbar-inner{width:93%;display: flex;align-items: center;}
.ml-navbar>.ml-navbar-inner>.ml-navbar-left{width:140px;padding:5px 0;transition-timing-function:linear;transition-duration:0.5s;}
.ml-navbar .ml-navbar-left .one{
    width:100%;
}
.ml-navbar .ml-navbar-left .two{
    display: none;
}
.ml-navbar>.ml-navbar-inner>ul{display: flex;list-style-type: none;margin: 0px;}



.items .nav-arrow{transform: translateX(-12px);visibility: hidden; color: #000;}
.items .item-name{padding:0 5px;color: #000;}
.items .nav-arrowDown{transform: rotate(0deg);}
.items:hover .nav-arrow{visibility: visible;transform: translateX(0px);color: #000 !important;}
.nav-item:hover .nav-arrowDown{transform: rotate(180deg);}
.ml-navbar .items{
    position: relative;
    padding:0 0 0 30px;
    display: flex;
    align-items: center;
}
.nav-item{
    text-decoration: none;
    color: #000;
    font-weight: 400;
}
.ml-navbar .items .nav-item-menu{
    width:200px;  
    display: none;
    flex-direction: column;
    position:absolute;
    top:70%;
    list-style-type: none;
    padding:20px 0;
    margin:0;
    color:white;
    border-radius:3px;
    transition-property:all;transition-timing-function:linear;transition-duration:0.5s;    
}
.items:hover .nav-item-menu{
    display: flex;
}
.nav-item-menu li{
    display: flex;
    margin-bottom:2px;
} 
.nav-item-menu>li>a{
    width:100%;
    height: 100%;
    position: relative;
}
.nav-item-menu>li>a>span:last-child{
    position: relative;
    padding-left:3px;
}
.nav-item-menu>li>a>span:first-child{
    position: absolute;
    left:0;
    top:0;
    width:3px;
    height: 100%;
} 
.nav-item-menu>li>a:hover .sp{
    width: 100%;
}
.arrowDown{ 
    transition-property:all;
    transition-timing-function:linear;
    transition-duration:0.3s;
}


.ml-navbar-middle{
    display: flex;
    align-items: center;
    /* justify-content: end; */
    padding-left: 0px;
}

.newflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* mlnavbar small */
.ml-navbar-small{
    display:none;
    justify-content:center;
   width:100%;
   overflow: hidden;
   position: fixed;
   top:0px;
   left: 0px;
   z-index: 100;
   /* border-bottom: 1px solid var(--tertiary-color); */
}
.ml-navbar-small-move{
    top:-400px
}
.ml-navbar-small>.ml-navbar-inner{
    width:90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.ml-navbar-small>.ml-navbar-inner>.ml-navbar-left{
    /* width:120px; */
    width:100px;
    padding:11px 0;
    display: flex;
    align-items: center;
    transition-timing-function:linear;
    transition-duration:0.5s;
}
.ml-navbar-small>.ml-navbar-inner>.ml-navbar-right{
    display: flex;
    justify-content:flex-end;
    align-items:center;
}
.ml-navbar-small .sp-cover{display: flex;flex-direction: column;cursor: pointer;}
.ml-navbar-small .sp{width:40px;border-bottom:2px solid var(--brand-color);height:0px;margin-bottom:8px;}
.ml-navbar-small .sp:last-child{margin-bottom:0;}
.ml-navbar-small .sp-2{width:30px;}
.ml-navbar-small .sp-3{width:35px;}


.bg-black{
    background-color: #000;
}

.text-black{
    color: #000 !important;
}

.ml-navbar.active ul li .nav-item .item-name{
    color: #000 !important;
}

.ml-navbar.active ul li .nav-item .nav-arrow{
    color: #000 !important;
}



.btncust{
    border: 2px solid var(--white) !important;
    border-radius: 15px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: transparent !important;
  }
  

@media screen and (max-width:970px) 
{
    .ml-navbar-small{display: flex;}
    .ml-navbar{transform: translateX(-1000px);align-items: flex-start;height: 100vh;width:auto;}
    .ml-navbar-trans{transform: translateX(0px);}
    .ml-navbar>.ml-navbar-inner{flex-direction: column;width: 100%;height: 100%;}
    .ml-navbar>.ml-navbar-inner>.ml-navbar-left{
        width: 100% !important;
        padding:10px 0 !important;
        display: flex;
        align-items: center;
        /* margin:10px 0px; */
        justify-content: space-between;
    }
    /* .ml-navbar .ml-navbar-left .one{
        display: none;
    } */
    .ml-navbar .ml-navbar-left .two{
        display: flex;
        cursor: pointer;
        margin-right: 10px;
    }
    .ml-navbar>.ml-navbar-inner>ul{display: flex;flex-direction: column;width: 100%;margin-bottom:0;
    padding: 0 !important;
    }
    .ml-navbar .items{
        flex-direction: column;
        align-items: flex-start;
        padding:10px 10px;
        margin:10px 0;
        margin: 0;
        width: 200px;
    }
    .ml-navbar>.ml-navbar-inner>ul>li:hover{
        background-color: var(--ml);
    }
    .ml-navbar .items{
        position: relative;
    }
    .ml-navbar .items .nav-item-menu{
        left: 200px;
        top:0;
        z-index: 100;
    }

    .ml-navbar-middle{
        flex-direction: column;
    }

    .newflex{
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding-bottom: 20px;
    }
}
@media screen and (max-width:550px) {
    .ml-navbar-small>.ml-navbar-inner>.ml-navbar-left{
        /* width:90px; */
        width:120px;
    }
}

@media screen and (max-width: 400px){
    .ml-navbar .items .nav-item-menu{
        width: 120px !important;
    }
}

@media (max-width: 1199px){
    .ml-navbar .items{
        padding:10px 0 0 0px;
    }
}